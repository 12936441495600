<template>
    <div style="min-height: 200px">
        <div class="table-responsive">
            <table id="trial" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Group level 1</th>
                        <th>Group level 2</th>
                        <th>Group level 3</th>
                        <th>Group level 4</th>
                        <th>Product / Service Name</th>
                        <th>Last Purchase Rate</th>
                        <th class="text-end">Opening (Qty)</th>
                        <th class="text-end">In (Qty)</th>
                        <th class="text-end">Out (Qty)</th>
                        <th class="text-end">Closing (Qty)</th>
                        <th class="text-end">Opening (Value)</th>
                        <th class="text-end">In (Value)</th>
                        <th class="text-end">Out (Value)</th>
                        <th class="text-end">Closing (Value)</th>
                        <th class="text-end">Sale (Value)</th>
                        <th class="text-end">Profit (Value)</th>
                    </tr>
                </thead>

                <tbody v-if="balances.length">
                    <tr v-for="(item, i) in balances" :key="i">
                        <td>{{ item.type }}</td>
                        <td>{{ item.group_levels.group_level_1 }}</td>
                        <td>{{ item.group_levels.group_level_2 }}</td>
                        <td>{{ item.group_levels.group_level_3 }}</td>
                        <td>{{ item.group_levels.group_level_4 }}</td>
                        <td>{{ item.product_name }}</td>
                        <td>{{ item.last_purchase_rate }}</td>
                        <td class="text-end">{{ item.opening_stock ? commaFormat(item.opening_stock) : 0 }}</td>
                        <td class="text-end">{{ item.in_quantity ? commaFormat(item.in_quantity) : 0 }}</td>
                        <td class="text-end">{{ item.out_quantity ? commaFormat(item.out_quantity) : 0 }}</td>
                        <td class="text-end">{{ item.closing_stock ? commaFormat(item.closing_stock) : 0 }}</td>
                        <td class="text-end">{{ item.opening_value ? commaFormat(item.opening_value) : 0 }}</td>
                        <td class="text-end">{{ item.in_amount ? commaFormat(item.in_amount) : 0 }}</td>
                        <td class="text-end">{{ item.out_amount ? commaFormat(item.out_amount) : 0 }}</td>
                        <td class="text-end">{{ item.closing_value ? commaFormat(item.closing_value) : 0 }}</td>
                        <td class="text-end">{{ item.total_sale_value ? commaFormat(item.total_sale_value) : 0 }}</td>
                        <td class="text-end">{{ item.total_profit_value ? commaFormat(item.total_profit_value) : 0 }}</td>
                    </tr>
                    <tr class="bg-blue-light">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>TOTAL</strong></td>
                        <td class="text-end">{{ commaFormat(getTotal('opening_stock')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('in_quantity')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('out_quantity')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('closing_stock')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('opening_value')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('in_amount')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('out_amount')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('closing_value')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('total_sale_value')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('total_profit_value')) }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-if="!balances.length" class="mt-5 text-center">No data</p>
        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
export default {
    name: 'StockRegisterTable',
    props: {
        acName: String,
        balances: {
            default: [],
            type: Array
        }
    },
    methods: {
        getTotal (key) {
            let total = 0;
            this.balances.map(i => {
                if(i[key]) {
                    total += i[key]
                }
            })
            return total
        },
    },
    setup() {
        const {commaFormat} = figureFormatter ()

        return {
            commaFormat
        }
    },
}
</script>

<style scoped>
#trial td{
    padding: 2px
}
#trial th{
    padding: 10px 3px;
}
</style>
